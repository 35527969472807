@import url(https://fonts.googleapis.com/css2?family=Open+Sans&family=Poiret+One&display=swap);
html {
  /* background-color: #FED700; */
  height: 100vh;
}

body {
  height: 100vh;
  margin: 0;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F2D680;
  color: #1A261D;

}

h1 {
  font-family: 'Poiret One', cursive;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.section {
  display: flex;
}

.hero-body {
  max-width: 600px;
  margin: 0 auto;
  background-color: #f5f5f5de;
  border: 5px solid #1A261D;
}

.description {
  margin-bottom: 20px;
}

h2 {
  margin-bottom: 10px;
}

ul>li {
  margin-bottom: 15px;
}

a {
  color: #374BA6;
}

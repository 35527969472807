@import '~bulma/css/bulma.css';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Poiret+One&display=swap');

.section {
  display: flex;
}

.hero-body {
  max-width: 600px;
  margin: 0 auto;
  background-color: #f5f5f5de;
  border: 5px solid #1A261D;
}

.description {
  margin-bottom: 20px;
}

h2 {
  margin-bottom: 10px;
}

ul>li {
  margin-bottom: 15px;
}

a {
  color: #374BA6;
}